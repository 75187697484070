import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import "react-dropdown/style.css"
import FaqDropDown from "../components/Product/FaqDropdown"
import Layout from "../components/layout"
import Booklet from "../pages/Dianne Rowe Private Collection Your Bespoke Journey.pdf"

const FaqPage = () => {
  return (
    <Layout>
      <SEO
        title="FAQ"
        keywords={[`australian`, `jewellery`, `exclusive`]}
        description={`Contact us to create your custom, bespoke engagtement ring, wedding jewellery, wedding ring, present. Gold and ethical gemstones (lab diamond, moissanite, morganite, sapphire)`}
      />
      <div className="faqwrap">
        <div className="faqpic"></div>
        <div className="faqbuffer">
          <div className="faqtitle">
            <div className="faqtext">FAQ</div>
          </div>
          <div className="faqblurb">
            We've curated a list of our most frequently asked questions below.
            Take a browse, think it over and send us a message if you can't find
            what you're looking for here.
          </div>
          <FaqDropDown text="How do I care for my gold?" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Yellow and rose gold have a natural seal around their outer edge.
              Your jewellery will require gentle cleaning and polishing to
              maintain it’s high shine. All of our white gold pieces are rhodium
              plated. Rhodium is a hard metal within the Platinum family which
              creates a tough, lustrous and shiny coat on the surface of your
              jewellery. We recommend rhodium re-plating every 6 – 18 months
              depending on how often you wear your jewellery. At the end of the
              day, you don’t have to re-plate your white gold jewellery if you
              don’t want to. Your ring will develop a more organic and unique
              character which you won’t find elsewhere.
            </div>
          </FaqDropDown>
          <FaqDropDown
            text="Wearing fine gold rings next to silver / platinum / costume jewellery"
            isOpen
          >
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Your look is unique and should be an expression of you. Stacking
              and layering jewellery can make a statement. Just be mindful that
              mixing silver / platinum / costume jewellery with your fine pieces
              may cause damage, scratching, pitting and erosion. We recommend
              only wearing ‘like-with-like’. This means keeping golds together
              and away from contact with other metals. This is a protective
              measure for ensuring your jewellery gives you many years of
              happiness and fond memories.
            </div>
          </FaqDropDown>
          <FaqDropDown text="Caring for gemstones" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Each gemstone can be graded on hardness on the Mohs scale.
              Diamonds take the top spot with a value of 10, and softer stones
              like opal have a value of 5.5 - 6.5. The softer the gemstone, the
              more care needs to be taken when cleaning your jewellery.
              Gemstones softer than 7 Mohs can be scratched with careless
              cleaning as any dust or dirt that has settled on your piece may be
              hard enough to scratch the surface if rubbed. We also recommend
              caution with activities of daily living. Hot temperatures,
              prolonged submersion in water, pool chemicals, perfumes, soaps and
              lotions may negatively impact stones.
            </div>
          </FaqDropDown>
          <FaqDropDown text="General jewellery care" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              We recommend removing all jewellery whilst sleeping. This can
              prevent bending / warping of rings, tangling of necklaces, loss of
              earring backings, catching of prongs and loosening of stones. This
              also extends to activities like exercise, gardening, cleaning and
              manual labour. The gold standard rule is to keep your jewellery
              safe and free from chemicals, impact, dirt, heat and increased
              force. We advise against ultrasonic cleaning for delicate pieces
              with pavé set stones and pieces with delicate gemstones.
            </div>
          </FaqDropDown>
          <FaqDropDown text="How do I clean my jewellery at home?" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              We recommend using a gentle, environmentally friendly dishwashing
              detergent, warm water and soft toothbrush to bring that sparkle
              back to your piece. Make sure to gently remove any build-up from
              around and underneath your gemstone(s) using the toothbrush,
              detergent and warm water to restore your gemstone’s brilliance.
              Rinse and softly dry with a lint-free cloth. You can then use you
              included gold polishing cloth to gently restore your gold’s
              surface to it’s finest shine.
            </div>
          </FaqDropDown>
          <FaqDropDown text="Do your pieces have a warranty?" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Yes, they do. You can see our full warranty terms and conditions
              <a> </a>
              <a href="/shipping-returns">
                <u>here.</u>
              </a>
            </div>
          </FaqDropDown>
          <FaqDropDown text="How do I find out my ring measurement? " isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              If you’re a Perth local, feel free to contact us to arrange a time
              to organise a ring sizing. If not, we recommend attending a local
              jeweller who can help you select your ring size. It’s important to
              know that your finger size will fluctuate through the day as well
              as seasons of the year. In summer your finger size will generally
              be larger than in winter. This applies for travelling to warmer or
              cooler climates. Pregnancy and changes in health may cause fingers
              to change size. Even exercise will have an impact! For your most
              accurate measurement, we recommend taking a measurement around two
              in the afternoon somewhere close to home. If you’ve got time, it’s
              worth measuring in different weather to attain an average size. A
              well-fitting ring will be met with slight resistance when sliding
              over the knuckle whilst sitting firmly in a constriction free
              manner when in situ.
            </div>
          </FaqDropDown>
          <FaqDropDown text="Where is your Jewellery made?" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Every Dianne Rowe Private Collection piece is delicately designed
              in Perth, Western Australia. Your piece will be masterfully
              handcrafted by an assiduously chosen artisan master jeweller who
              specialises in the style that you’ve got your heart set on. We
              have master craftspeople located across the globe who have
              branched out after working with many well-known major companies.
              Working with these industry specialists allows us to give you the
              same incredible expertise and quality without the mark-up.
            </div>
          </FaqDropDown>
          <FaqDropDown
            text="Can you make something with a stone that I already have?"
            isOpen
          >
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Client sourced gemstones generally come with a strong source of
              sentimental attachment. Quite often, these will be heirloom pieces
              that have been within a family for generations. Gemstones with
              such sentimental attachment are irreplaceable. Even with
              insurance, money can never cover any accidental loss or damages
              incurred during the manufacturing process. We have also seen
              unfortunate cases where independently sourced gemstones have been
              mis-advertised and are not what they claim to be! For this reason,
              we continue to source our own high-quality mining and conflict
              free gemstones and at this stage do not offer creation of new
              designs with existing gemstones.
            </div>
          </FaqDropDown>
          <FaqDropDown
            text="I want one of your designs but with a different colour gold / gemstone. "
            isOpen
          >
            <div className="faqtext" style={{ textAlign: "justify" }}>
              We’re all about customisation here. We actually encourage it! We
              want your jewellery to fully reflect your personality and style.
              Send us a message from the{" "}
              <a href="/contact">
                <u> contact us page</u>
              </a>{" "}
              , or come and say hi via email – info@rowecollection.com.au
            </div>
          </FaqDropDown>
          <FaqDropDown text="Where do you source your gemstones from?" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Each of our gemstones is meticulously hand sourced from companies
              that are committed to sustainable environmental practice. We pride
              ourselves on only using mining and conflict free gemstones. These
              are generally lab created or selectively reclaimed from vintage
              pieces that meet stringent quality controls. We want to ensure
              that your gemstone is the best that money can buy ensuring the
              clarity, cut, colour, symmetry and polish meet our rigorous
              selection criteria.
            </div>
          </FaqDropDown>
          <FaqDropDown
            text="I want a specific gemstone. Can you find it for me? "
            isOpen
          >
            <div className="faqtext" style={{ textAlign: "justify" }}>
              We can most certainly try! We have working arrangements with many
              companies based all around the world. Get in contact with us and
              we can begin the search for your dream gemstone.
            </div>
          </FaqDropDown>
          <FaqDropDown text="What is the right gemstone for me?" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              The right gemstone will be something that makes you smile, is
              strong enough to withstand the pace of your lifestyle and meets
              your budget. We specialise in mining and conflict free gemstones
              and can help you choose the right fit for your style and
              lifestyle.
            </div>
          </FaqDropDown>
          <FaqDropDown text="Why do you recommend 14k gold settings?" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              The gold used to make your jewellery setting is an alloy. This
              means that pure gold is mixed with other metals to take on
              different favourable characteristics. Pure gold is 24 karat (24K)
              gold which means the ‘alloy’ is 24/24 parts (100%) gold. Pure gold
              is far too soft for everyday wear and any setting made with this
              metal would end up with scratches, lost gemstones and lots of
              crying. 14k gold settings are made from 14/24 parts gold to alloy
              equating to 58.3% gold. This is the industry bridal standard in
              may countries because it offers excellent strength for daily wear
              which equates to a more durable setting. This means less
              scratching, bending and scuffing and more peace of mind.
            </div>
          </FaqDropDown>
          <FaqDropDown text="I don't want a 14k gold setting. " isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              That’s no problem at all! We can make your dream setting using 10k
              or 18k gold, or platinum if you’ve got the budget for it.
            </div>
          </FaqDropDown>
          <FaqDropDown text="What's the deal with platinum?" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Platinum is an amazing metal to craft a setting from. It maintains
              its beautiful tarnish resistant white colour without needing
              rhodium plating. Platinum is heavy by nature and jewellery made
              with this metal will feel weighty. Unlike gold, when your setting
              gets ‘scratched’ the platinum is not lost, only displaced! It also
              has hypoallergenic properties which are excellent for people with
              metal hypersensitivities. Despite all of these amazing properties,
              platinum rings will require an extension of your budget. If you’re
              having a ring made in platinum, make sure you’ve got the sizing
              absolutely right. Once your ring is made, resizing is off the
              cards. This is because platinum has a very high melting point, and
              gemstones in the setting can be damaged from the heat during
              adjustments.
            </div>
          </FaqDropDown>
          <FaqDropDown
            text="What is the best way to begin my bespoke journey?"
            isOpen
          >
            <div className="faqtext" style={{ textAlign: "justify" }}>
              We’d love to hear from you. Feel free to send us a message via the
              <a href="/contact">
                <a> </a>
                <u>contact link</u>
              </a>{" "}
              at the top of the website, or alternatively send an email to
              info@rowecollection.com.au
            </div>
          </FaqDropDown>
          <FaqDropDown text="Do you charge for a quote? " isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              No. All design services are free! We are here to make your dream
              designs a reality and won’t take a deposit until we’re sure we can
              bring your ideas to life.
            </div>
          </FaqDropDown>
          <FaqDropDown
            text="I don't know exactly what I want. Is it ok to still contact you?"
            isOpen
          >
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Absolutely. If you’re stuck for ideas, feel free to download our
              free Bespoke Design Guide{" "}
              <a href={Booklet} download className="Bookletlink">
                <u>here.</u>
              </a>
            </div>
          </FaqDropDown>
          <FaqDropDown
            text="Can we start with a budget and work from there? "
            isOpen
          >
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Yes! No matter what your budget, we can work hard to create
              something that will take your breath away.
            </div>
          </FaqDropDown>
          <FaqDropDown
            text="What are the production times for bespoke jewellery?"
            isOpen
          >
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Depending on the complexity of your design (hand-crafted elements
              / fancy cut gemstones / difficult to procure gemstones), the
              design process can take 4-10 weeks. Manufacturing can take between
              4-8 weeks once the design has been signed off and deposit paid.
            </div>
          </FaqDropDown>
          <FaqDropDown
            text="Can you help me? I don't live in Australia but want a custom design. "
            isOpen
          >
            <div className="faqtext" style={{ textAlign: "justify" }}>
              Yes. We have made may designs successfully for customers all
              around the world. Come and say hello by sending us an email at
              info@rowecollection.com.au or filling in the ‘contact us’ form
              linked at the top of this website.
            </div>
          </FaqDropDown>
          <FaqDropDown text="Can you make designs for men and women?" isOpen>
            <div className="faqtext" style={{ textAlign: "justify" }}>
              We know that life is far from monochromatic and encourage men,
              women and all within the LGBTQI+ community to love themselves and
              be authentic to who they are. No matter who you are or how you
              identify, if you want to rock some bling, we’re here to help you
              make it happen! Be strong. Be proud. Be yourself. I want something
              other than what you’ve got on your website (tie pin / cufflinks
              etc). Can you make it? Let’s try! We’d love to hear about your
              idea and see if we can turn them into reality.
            </div>
          </FaqDropDown>
          <FaqDropDown
            text="How can I let my insurer know how much my jewellery is worth?"
            isOpen
          >
            <div className="faqtext" style={{ textAlign: "justify" }}>
              As part of your purchase you will receive an official Dianne Rowe
              Private Collection authenticity card and Valuation Document. This
              will outline the details of your piece and ensure that you can
              obtain insurance for the total recommended retail value of your
              goods. We recommend insuring your piece for theft, accidental
              damage and loss.
            </div>
          </FaqDropDown>
        </div>
      </div>
    </Layout>
  )
}

export default FaqPage

export const MyQuery = graphql`
  {
    imageSharp(fluid: { originalName: { eq: "Headerabout.jpg" } }) {
      id
      fluid {
        srcSet
      }
    }
  }
`
